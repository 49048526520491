export function initBackground() {
  const canvas = document.createElement('canvas');
  canvas.classList.add('background-canvas');
  document.body.prepend(canvas);

  const ctx = canvas.getContext('2d')!;
  let scrollPosition = 0;

  window.addEventListener('scroll', () => {
    scrollPosition = window.scrollY;
  });

  function resizeCanvas() {
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
  }

  function drawBackground(ctx: CanvasRenderingContext2D) {
    // Sky gradient
    const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
    gradient.addColorStop(0, '#ffffff');  // Pure white at top
    gradient.addColorStop(1, '#f5f5f7');  // Light gray at bottom
    
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Background mountain range (moves slower)
    ctx.save();
    ctx.filter = 'blur(8px)';
    ctx.shadowColor = '#ebebeb';
    ctx.shadowBlur = 15;
    
    const bgMountainColor = '#ebebeb';
    const bgMountainOffset = (scrollPosition * 0.1) % canvas.width;

    ctx.beginPath();
    ctx.fillStyle = bgMountainColor;
    
    ctx.moveTo(-200 - bgMountainOffset, canvas.height);
    
    // Background mountains
    const points = [
      // First mountain group
      [100, 0.75], [117, 0.73], [133, 0.72], [150, 0.71], [167, 0.70], [183, 0.69],
      [200, 0.68], [217, 0.69], [233, 0.71], [250, 0.72], [267, 0.71], [283, 0.70],
      [300, 0.7], [317, 0.69], [333, 0.68], [350, 0.67], [367, 0.66], [383, 0.65],
      [400, 0.65], [417, 0.66], [433, 0.68], [450, 0.69], [467, 0.70], [483, 0.71],
      [500, 0.72], [517, 0.71], [533, 0.69], [550, 0.68], [567, 0.70], [583, 0.72],
      [600, 0.73], [617, 0.76], [633, 0.80], [650, 0.85], [667, 0.90], [683, 0.95],
      [700, 1],
      // Second mountain group
      [750, 0.82], [767, 0.78], [783, 0.73], [800, 0.68], [817, 0.69], [833, 0.70],
      [850, 0.71], [867, 0.69], [883, 0.67], [900, 0.65], [917, 0.66], [933, 0.67],
      [950, 0.68], [967, 0.69], [983, 0.70], [1000, 0.7], [1017, 0.69], [1033, 0.67],
      [1050, 0.66], [1067, 0.65], [1083, 0.64], [1100, 0.63], [1117, 0.67], [1133, 0.71],
      [1150, 0.75], [1167, 0.77], [1183, 0.80], [1200, 0.82], [1217, 0.84], [1233, 0.87],
      [1250, 0.89], [1267, 0.93], [1283, 0.96], [1300, 1],
      // Third mountain group
      [1350, 0.85], [1367, 0.82], [1383, 0.77], [1400, 0.73], [1417, 0.74], [1433, 0.76],
      [1450, 0.77], [1467, 0.76], [1483, 0.75], [1500, 0.75], [1517, 0.74], [1533, 0.73],
      [1550, 0.72], [1567, 0.71], [1583, 0.70], [1600, 0.7], [1617, 0.71], [1633, 0.73],
      [1650, 0.74], [1667, 0.75], [1683, 0.76], [1700, 0.77], [1717, 0.79], [1733, 0.81],
      [1750, 0.83], [1767, 0.85], [1783, 0.87], [1800, 0.89], [1817, 0.91], [1833, 0.93],
      [1850, 0.94], [1867, 0.96], [1883, 0.98], [1900, 1/0.8]
    ];

    points.forEach(([x, heightFactor]) => {
      ctx.lineTo((x * 1.5) - bgMountainOffset, canvas.height * (heightFactor * 0.8));
    });
    
    ctx.fill();
    ctx.restore();

    // Foreground mountain range
    ctx.save();
    ctx.filter = 'blur(4px)';
    ctx.shadowColor = '#e0e0e0';
    ctx.shadowBlur = 10;
    
    const mountainColor = '#e0e0e0';
    const mountainOffset = (scrollPosition * 0.2) % canvas.width;

    ctx.beginPath();
    ctx.fillStyle = mountainColor;
    
    ctx.moveTo(-200 - mountainOffset, canvas.height);
    
    // Foreground mountains
    const fgPoints = [
      // First mountain group
      [100, 0.6], [117, 0.58], [133, 0.56], [150, 0.55], [167, 0.53], [183, 0.51],
      [200, 0.5], [217, 0.51], [233, 0.52], [250, 0.52], [267, 0.53], [283, 0.54],
      [300, 0.55], [317, 0.54], [333, 0.52], [350, 0.51], [367, 0.49], [383, 0.47],
      [400, 0.45], [417, 0.46], [433, 0.47], [450, 0.48], [467, 0.51], [483, 0.55],
      [500, 0.58], [517, 0.59], [533, 0.61], [550, 0.62], [567, 0.65], [583, 0.68],
      [600, 0.7], [617, 0.75], [633, 0.80], [650, 0.85], [667, 0.90], [683, 0.95],
      [700, 1],
      // Second mountain group
      [750, 0.65], [767, 0.63], [783, 0.61], [800, 0.6], [817, 0.58], [833, 0.56],
      [850, 0.55], [867, 0.53], [883, 0.51], [900, 0.5], [917, 0.51], [933, 0.52],
      [950, 0.53], [967, 0.54], [983, 0.56], [1000, 0.57], [1017, 0.56], [1033, 0.55],
      [1050, 0.54], [1067, 0.55], [1083, 0.57], [1100, 0.58], [1117, 0.60], [1133, 0.63],
      [1150, 0.65], [1167, 0.68], [1183, 0.72], [1200, 0.75], [1217, 0.78], [1233, 0.82],
      [1250, 0.85], [1267, 0.90], [1283, 0.95], [1300, 1],
      // Third mountain group
      [1350, 0.7], [1367, 0.65], [1383, 0.60], [1400, 0.55], [1417, 0.54], [1433, 0.53],
      [1450, 0.52], [1467, 0.51], [1483, 0.49], [1500, 0.48], [1517, 0.49], [1533, 0.50],
      [1550, 0.51], [1567, 0.52], [1583, 0.53], [1600, 0.53], [1617, 0.54], [1633, 0.56],
      [1650, 0.57], [1667, 0.59], [1683, 0.61], [1700, 0.62], [1717, 0.65], [1733, 0.68],
      [1750, 0.7], [1767, 0.73], [1783, 0.77], [1800, 0.8], [1817, 0.83], [1833, 0.87],
      [1850, 0.9], [1867, 0.93], [1883, 0.96], [1900, 1/1.1]
    ];

    fgPoints.forEach(([x, heightFactor]) => {
      ctx.lineTo(x - mountainOffset, canvas.height * heightFactor * 1.1);
    });
    
    ctx.fill();
    ctx.restore();
  }

  function animate() {
    drawBackground(ctx);
    requestAnimationFrame(animate);
  }

  window.addEventListener('resize', resizeCanvas);

  resizeCanvas();
  animate();
} 