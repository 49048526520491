import './style.css'
import { initBackground } from './background'

// Initialize the background
initBackground()

document.querySelector<HTMLDivElement>('#app')!.innerHTML = `
  <div class="container">
    <header>
      <h1>Dr. Jörg Bartnick</h1>
      <h2>Data Scientist and Software Engineer for AI Products</h2>
    </header>

    <section class="about">
      <p>
        Experienced professional combining expertise in physics, data science, and software engineering.
        Specializing in AI products, machine learning, and high-performance computing.
      </p>
    </section>

    <section class="experience">
      <h3>Recent Experience</h3>
      <div class="role">
        <h4>AI Search - trivago, Düsseldorf</h4>
        <p class="date">May 2024 – April 2025</p>
        <ul>
          <li>Development of AI search as a new feature for trivago's hotel search platform</li>
          <li>Responsible for backend development, AI engineering, and data analysis</li>
        </ul>
      </div>

      <div class="role">
        <h4>Senior Software Engineer - Data & Analytics - Mazars Germany</h4>
        <p class="date">May 2023 – April 2024</p>
        <ul>
          <li>Early member of Data & Analytics innovation team, establishing Databricks environment in Azure Cloud</li>
          <li>Developed ETL pipelines and machine learning tools for internal use</li>
          <li>Contributed to team building and recruitment while driving innovation initiatives</li>
        </ul>
      </div>
    </section>

    <section class="publications">
      <h3>Selected Publications</h3>
      <div class="publication">
        <h4>Statistical Mechanics where Newton's Third Law is Broken</h4>
        <p class="authors">Ivlev, Bartnick, Heinen, Du, Nosenko, and Löwen</p>
        <p class="journal">Physical Review X <strong>5</strong>, 011035 (2015)</p>
        <div class="publication-links">
          <a href="https://journals.aps.org/prx/abstract/10.1103/PhysRevX.5.011035" target="_blank">View Paper</a>
          <a href="http://phys.org/news/2015-05-newton-law-broken.html" target="_blank">Press Coverage</a>
        </div>
      </div>

      <div class="publication">
        <h4>Emerging activity in bilayered dispersions with wake-mediated interactions</h4>
        <p class="authors">Bartnick, Kaiser, Löwen, and Ivlev</p>
        <p class="journal">Journal of Chemical Physics <strong>144</strong>, 22 (2016)</p>
        <div class="publication-links">
          <a href="http://aip.scitation.org/doi/10.1063/1.4953225" target="_blank">View Paper</a>
        </div>
      </div>

      <div class="publication">
        <h4>Structural correlations in binary diffusiophoretic mixtures with nonreciprocal interactions</h4>
        <p class="authors">Bartnick, Heinen, Ivlev, and Löwen</p>
        <p class="journal">Journal of Physics: Condensed Matter <strong>28</strong>, 2 (2015)</p>
        <div class="publication-links">
          <a href="http://dx.doi.org/10.1088/0953-8984/28/2/025102" target="_blank">View Paper</a>
        </div>
      </div>
    </section>

    <section class="skills">
      <h3>Core Technologies</h3>
      <ul class="skill-list">
        <li>Cloud Platforms: GCP, Azure</li>
        <li>AI/ML: Gemini, OpenAI, Natural Language Processing</li>
        <li>Languages: Kotlin, Python, Java, C++</li>
        <li>Big Data: Databricks, ElasticSearch, Hadoop</li>
      </ul>
    </section>

    <footer>
      <div class="contact">
        <h3>Contact</h3>
        <p>Currently based in Tenerife, Spain</p>
        <a href="mailto:joerg@bartnick.eu">joerg@bartnick.eu</a>
        <a href="https://www.linkedin.com/in/bartnick/" target="_blank">LinkedIn Profile</a>
      </div>
    </footer>
  </div>
`
